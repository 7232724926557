export const AppleMusic =
  'M23 6.62c0-.68-.07-1.35-.23-2.01a4.52 4.52 0 0 0-2-2.8 4.61 4.61 0 0 0-1.72-.66 9.18 9.18 0 0 0-1.43-.14L17.51 1H6.48l-.41.02a6.3 6.3 0 0 0-2.01.37A4.57 4.57 0 0 0 1.1 5.23 9.05 9.05 0 0 0 1 6.31V17.6l.02.4c.05.74.14 1.48.46 2.17.6 1.3 1.6 2.15 2.97 2.56.38.12.78.18 1.18.21.5.05 1.02.06 1.53.06h10.1c.49 0 .97-.03 1.45-.1.75-.09 1.46-.31 2.1-.73a4.63 4.63 0 0 0 1.73-2.03c.17-.38.26-.8.34-1.21.1-.62.12-1.25.12-1.87V6.6Zm-5.9 3.65v5.24c0 .38-.05.76-.22 1.1-.27.54-.7.88-1.27 1.05a4.15 4.15 0 0 1-.98.16 1.7 1.7 0 0 1-1.78-1.41 1.72 1.72 0 0 1 .95-1.86c.3-.14.61-.23.93-.3.35-.06.7-.13 1.04-.21a.57.57 0 0 0 .47-.48.73.73 0 0 0 .01-.17v-5a.69.69 0 0 0-.02-.16c-.04-.14-.14-.22-.28-.22-.15.01-.29.04-.43.06l-2.1.42-3.38.69h-.05c-.26.08-.35.2-.36.46V16.91c0 .39-.04.77-.2 1.13-.25.58-.7.95-1.31 1.13a4 4 0 0 1-.99.16c-.88.03-1.6-.56-1.76-1.42a1.71 1.71 0 0 1 1.06-1.9c.33-.14.67-.22 1.02-.29l.78-.16c.35-.07.54-.3.56-.65V6.63c0-.12.01-.23.04-.34.06-.26.24-.41.5-.48.23-.06.47-.1.7-.15l2.02-.4 2.08-.43 1.84-.37c.2-.04.41-.08.61-.1.29-.02.48.16.51.45l.01.2v5.26Z';

export const Soundcloud =
  'M6 18h1.5V8.55a3.86 3.86 0 0 0-1.5.83V18Zm-3-6.39v6.38l.1.01h1.4v-6.4H3.1l-.1.01ZM0 14.8c0 1.16.6 2.17 1.5 2.73v-5.46A3.21 3.21 0 0 0 0 14.8ZM9 18h1.5V8.97A3.75 3.75 0 0 0 9 8.43V18Zm11.9-6.4h-.84A4.72 4.72 0 0 0 15.48 6c-1.39 0-2.63.64-3.48 1.64V18h8.9c1.71 0 3.1-1.43 3.1-3.2 0-1.77-1.39-3.2-3.1-3.2Z';

export const Deezer =
  'M24 4h-5.14v3.14H24V4ZM11.43 8.29H6.29v3.14h5.14V8.29Zm-5.14 4.28h5.14v3.14H6.29v-3.14Zm-1.15 4.29H0V20h5.14v-3.14Zm6.29 0H6.29V20h5.14v-3.14Zm1.14 0h5.14V20h-5.14v-3.14Zm5.14-4.29h-5.14v3.14h5.14v-3.14Zm1.15 4.29H24V20h-5.14v-3.14ZM24 12.57h-5.14v3.14H24v-3.14ZM18.86 8.3H24v3.14h-5.14V8.29Z';

export const Bandcamp =
  'M12 .5a11.5 11.5 0 1 0 0 23 11.5 11.5 0 0 0 0-23Zm2.23 15.12H5.84l3.93-7.24h8.4l-3.94 7.24Z';

export const Spotify =
  'M.5 12a11.5 11.5 0 1 1 23 0 11.5 11.5 0 0 1-23 0Zm18.26-1.34a1.07 1.07 0 0 0 1.67-.9c0-.55-.24-.83-.6-1.04C17.24 7.2 13.7 6.5 10.31 6.5c-2 0-3.82.23-5.45.7-.42.13-.8.48-.8 1.11 0 .62.46 1.1 1.07 1.1.19 0 .36-.05.51-.1l.1-.02c3.81-1.07 9.71-.6 13.02 1.37Zm-1.35 3.56a1 1 0 0 0 .5.16c.51 0 .9-.4.9-.9 0-.4-.14-.7-.52-.92a16.23 16.23 0 0 0-8.2-2.1c-1.93 0-3.25.26-4.54.62-.48.14-.72.47-.72.96 0 .5.4.9.9.9.15 0 .26-.03.4-.07l.15-.05a15.03 15.03 0 0 1 11.07 1.36l.06.04Zm-1.24 3.03c.18.11.3.17.5.17.38 0 .7-.3.7-.71 0-.43-.16-.59-.45-.77-3.3-1.98-7.19-2.05-10.99-1.21-.34.07-.63.3-.63.78 0 .37.29.73.74.73a2.03 2.03 0 0 0 .55-.12c3.32-.68 6.69-.61 9.58 1.13Z';

export const Facebook =
  'M1 12a11.1 11.1 0 0 0 9.19 10.89l.06-.05h-.01v-7.76H7.49V12h2.75V9.58c0-2.75 1.76-4.29 4.29-4.29.77 0 1.65.11 2.42.22v2.8h-1.43c-1.32 0-1.65.66-1.65 1.54V12h2.92l-.5 3.08h-2.42v7.75l-.1.02.05.04A11.1 11.1 0 0 0 23 12c0-6.05-4.95-11-11-11S1 5.95 1 12Z';

export const Twitter =
  'm20.75 7.48.01.59c0 6-4.54 12.93-12.84 12.93A12.71 12.71 0 0 1 1 18.96a9.02 9.02 0 0 0 6.68-1.88 4.52 4.52 0 0 1-4.22-3.16 4.47 4.47 0 0 0 2.04-.08A4.54 4.54 0 0 1 1.88 9.4v-.06c.63.35 1.33.55 2.05.57a4.57 4.57 0 0 1-1.4-6.07 12.78 12.78 0 0 0 9.3 4.75 4.57 4.57 0 0 1 1.31-4.34 4.5 4.5 0 0 1 6.39.2 9.02 9.02 0 0 0 2.86-1.11 4.56 4.56 0 0 1-1.98 2.51A8.93 8.93 0 0 0 23 5.13a9.2 9.2 0 0 1-2.25 2.35Z';

export const Instagram =
  'M7.44 1.08a8.03 8.03 0 0 0-2.66.51 5.38 5.38 0 0 0-1.95 1.28 5.39 5.39 0 0 0-1.26 1.95c-.28.7-.46 1.5-.51 2.67A78.4 78.4 0 0 0 1 12.02c0 2.99.02 3.36.08 4.54.05 1.17.24 1.97.51 2.66a5.39 5.39 0 0 0 1.27 1.95c.62.61 1.23.98 1.96 1.26.7.28 1.5.46 2.67.51 1.17.05 1.54.06 4.53.06s3.36-.02 4.54-.08a8.07 8.07 0 0 0 2.66-.51 5.4 5.4 0 0 0 1.95-1.27 5.4 5.4 0 0 0 1.26-1.95c.28-.7.46-1.5.51-2.67.05-1.18.06-1.55.06-4.54s-.02-3.36-.08-4.53a8.06 8.06 0 0 0-.51-2.67 5.4 5.4 0 0 0-1.28-1.95 5.37 5.37 0 0 0-1.95-1.26c-.7-.27-1.5-.46-2.67-.51A79.84 79.84 0 0 0 11.98 1c-2.99 0-3.36.02-4.54.08Zm.13 19.88a6.06 6.06 0 0 1-2.04-.37 3.43 3.43 0 0 1-1.27-.82 3.39 3.39 0 0 1-.82-1.26 6.11 6.11 0 0 1-.38-2.05c-.06-1.16-.07-1.5-.08-4.44 0-2.94 0-3.29.06-4.45a6.1 6.1 0 0 1 .37-2.04c.2-.51.44-.88.82-1.26a3.4 3.4 0 0 1 1.27-.83 6.1 6.1 0 0 1 2.04-.38 77.7 77.7 0 0 1 4.44-.07c2.94-.01 3.29 0 4.45.05a6.1 6.1 0 0 1 2.04.38c.51.2.88.43 1.27.82.38.38.62.75.82 1.26.15.39.33.97.38 2.04.06 1.16.07 1.5.08 4.44 0 2.94 0 3.29-.06 4.45a6.1 6.1 0 0 1-.37 2.04 3.41 3.41 0 0 1-.82 1.27c-.39.38-.75.62-1.27.82a6.1 6.1 0 0 1-2.04.39c-1.16.05-1.5.06-4.44.07-2.94 0-3.29 0-4.45-.06Zm8.97-14.84a1.32 1.32 0 1 0 2.64 0 1.32 1.32 0 0 0-2.64 0ZM6.35 12.01a5.65 5.65 0 1 0 11.3-.02 5.65 5.65 0 0 0-11.3.02Zm1.98 0a3.67 3.67 0 1 1 7.34-.02 3.67 3.67 0 0 1-7.34.02Z';

export const YouTube =
  'M21.38 3.54A3.12 3.12 0 0 1 23.5 5.8c.5 2 .5 6.19.5 6.19s0 4.18-.5 6.19a3.12 3.12 0 0 1-2.12 2.27C19.5 21 12 21 12 21s-7.5 0-9.38-.54A3.12 3.12 0 0 1 .5 18.2C0 16.19 0 12 0 12s0-4.18.5-6.19a3.12 3.12 0 0 1 2.12-2.27C4.5 3 12 3 12 3s7.5 0 9.38.54ZM9.6 8.14v7.72L15.84 12 9.6 8.14Z';
